import * as React from "react";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import "../styles/Index.scss";

import tuto from "../images/Processing-amico.svg";
import guides from "../images/Webinar-amico.svg";
import advanced from "../images/Development-amico.svg";

const IndexPage = () => {
  return (
    <Layout title="Accueil" noscroll>
      <div className="index-box">
        <div className="index-title">
          <h1>Bienvenue sur la documentation de Dotter.science.</h1>
          <h2>Comment pouvons-nous vous aider ?</h2>
        </div>
        <div className="index-paths">
          <a className="index-element" href="/fr/tutorial">
            <img src={tuto} alt="Tutoriel" />
            <h1>Tutoriels</h1>
          </a>
          <a className="index-element" href="/fr/guides">
            <img src={guides} alt="Guides" />
            <h1>Guides</h1>
          </a>
          <a className="index-element" href="/fr/advanced">
            <img src={advanced} alt="Fonctionnalités avancées" />
            <h1>Fonctionnalités</h1>
            <h1>avancées</h1>
          </a>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
