import React, { useState } from "react";

import { HelpOutline } from "@mui/icons-material";

import "./Footer.style.scss";

export default function Footer() {
  const [isOpen, setIsOpen] = useState(false);
  const [buttonClass, setButtonClass] = useState("footer-button");
  const [contentClass, setContentClass] = useState("footer-content");

  function handleClick() {
    if (isOpen === false) {
      setButtonClass("footer-button active");
      setContentClass("footer-content active");
      setIsOpen(true);
    } else {
      setButtonClass("footer-button");
      setContentClass("footer-content");
      setIsOpen(false);
    }
  }

  return (
    <div className="footer-root">
      <div className={buttonClass} onClick={handleClick}>
        <HelpOutline />
      </div>
      <div className={contentClass}>
        <span className="footer-link">
          <a href="https://dotter.science/" target="_blank">
            dotter.science{" "}
          </a>{" "}
          © 2022
        </span>
        <div>
          <span className="footer-link">
            <a href="mailto:contact@dotter.science">Nous contacter</a>
          </span>
        </div>
      </div>
    </div>
  );
}
